*{margin: 0%; padding: 0%;}

html{
    height: 100%;
}

.title{
    margin-bottom: 10px;
    color: #ff0f64;
    font-size: 46px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-display: swap;
}

.logo{
    width: auto;
    height: 100px;
    /* position: absolute;    */
}


/* .navbar{
    width: 100%;
    height: 100px;
    background-color: #ff0f64;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */


.list{
    background-color: white;
    width: 350px;
    margin: auto;
    margin-top: 0;
    height: max-content;
    list-style: none;
    position: absolute;
    top: 20px;
    border-radius: 15px;
    border: 3px solid #ff0f64;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: white;
    overflow-y: auto;
    max-height: 300px;
}

.list li{
    background-color: white;
    width: 90%;
    height: 20px;
    margin: auto;
    text-align: left;
    font-size: 18px;
    padding-left: 3px;
    padding-right: 3px;
}

.input~.list{
    position: absolute;
    z-index: 20;
    top: 50px;
    left: 10px;
    width: 360px;
}

.input{
    background-color: white;
    width: 350px;
    height: 50px;
    border-radius: 15px;
    margin: 10px;
    outline: none;
    padding-left: 10px;
}

.form{
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    place-items: center;
    z-index: 5;
    gap: 10px;
}

.submit{
    border-radius: 10px;    
    font-size: large;
    color: white;
    font-size: 24px;
    background: linear-gradient(90deg, rgba(255,15,100,1) 38%, rgba(121,9,81,1) 97%);
    border: none;
    transform: scale(1.05);
}

.submit:hover{
    cursor: pointer;
}

.sie{
    position: relative;
}

li{
    display: inline-block;

}

li a{
    color: white;
    text-decoration: none;
    font-size: 22px;
    font-weight: 500;
    padding: 20px;
}

.container{
    background-color: #ff0f64;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    height: 100px;
}

.nav-toggle{
    display: none;
    position: absolute;
    top: 2.3rem;
    right: 1.3rem;
}

.nav{
    margin-bottom: 30px;
}

.context{
    width: 40%;
    border-color: black;
    border-width: 3px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
    /* border: 6px solid #ff0f64; */
    border-radius: 14px;
    margin: auto;
    margin-top: 4%;
    background-color: white;
    height:  500px;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 20px;
}

@media all and (max-width: 720px) {
    .container{
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .nav{
        width: 100%;
        background-color: white;
        height: 0;
        overflow: hidden;
        transition: 250ms height ease-in-out;
        margin-bottom: 0;
    }

    li{
        display: block;
        text-align: center;
        padding: 10px;
    }

    li a{
        color: #ff0f64;
    }

    /* .logo{
        margin-top: -55px;
        pointer-events: none;
    } */

    .nav-toggle{
        display: block;
    }

    .navIcon{
        width: 30px;
    }


}

@media screen and (max-width: 920px){

    .context{
        margin-top: 5%;
        width: 92%;
        height: max-content;
        padding: 0;
    }

    .title{
        font-size: 33px;
    }

    .form{
        width: 90%;
    }

    .input{
        width: 100%;
    }

    .submit{
        width: 85%;
    }

    .input~.list{
        width: calc(103% + (1px));
    }

    .sie{
        width: 90%;
        margin-right: auto;
    }
}

