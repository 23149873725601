.bordo{
    border-radius: 20px;
}

.rDataContainer{
    background-color: white;
    height: fit-content;
    display: flex;
    padding: 6px;
    flex-direction: row;
    justify-content: space-around;
}

.rDataFrame{
    font-size: 23px;
    text-align: center;
    border-radius: 14px;
    font-weight: bold;
    margin: auto;
    width: 20%;
    background-color: #ff0f64;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80px;
}

.rDataTag{
    background-color: white;
    color: grey;
    max-width: 90%;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto;
    padding: 2px;
    border-radius: 10px;
}

.iTitle{
    color: #ff0f64;
    font-size: 40px;
    margin-left: 10px;
}

.stuff-container{
    margin: auto;
    margin-top: 30px;
    width: 40%;
    height: 500px;
    background-color: white;
}

.stuff-container-scroll{
    height: 87%;
    overflow-y: auto;
}

.response{
    font-size: 7vh;
    text-align: center;
    color: white;
    margin-top: 20px;
}


.coveredListContainer{
    width: 60%;
    margin: auto;
    margin-top: 30px;
    /* border: 2px solid #ff0f64; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    background-color: white;
    padding: 5px;
}

.coveredListTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    margin-right: 20px;
}

.clText{
    margin-left: 20px;
    font-size: 30px;
    color: #ff0f64;
    font-weight: bold;
}

.coveredListContainerScroll{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 350px;
    padding: 6px;
}

.coverItem{
    width: 98%;
    /* border: 2px solid grey; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 14px;
    margin-top: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 3px;
    font-size: 20px;
    font-weight: 600;
    color: #ff0f64;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.coverItemDatas{
    margin: auto;
    margin-left: 15px;
    font-weight: bold;
}

.coverItemLocation{
    float: right;
    margin: auto;
    margin-right: 15px;
    max-height: 35px;
    background-color: #ff0f64;
    color: white;
    border-radius: 12px;
    text-decoration: none;
    padding: 4px;
    padding-right: 6px; 
    padding-left: 6px;
    /* padding-bottom: 3px; */
}

.coverItemLocation:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

table {
    border: 1px solid black;
    border-collapse: collapse;
    border: none;
    text-align: center;
    width: 50%; 
    height: 170px;
    font-size: 20px;
    margin-top: 30px;
    border-radius: 0 0 16px 16px;
}


.tPar{
    background-color: #ff0f64;
    font-size: 24px;
    height: 70px;
    text-align: center;
}

tr{
    /* background-color: white; */
    text-align: center;
}


th:first-child{
    border-radius: 16px 0 0 0;
}

th:last-child{
    border-radius: 0 16px 0 0;
}

.cityStatusTable{
    width: 60%;
    margin-bottom: 30px;
}

.cityStatusTable td{
    border: 1px solid #dee2e6;
}


.rButton{

    /* Gap */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2.3rem;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 16px;
    border-color: transparent;
    background-color: #fff;
    color: #ff0f64;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    white-space: nowrap;
    width: 110px;
    height: 20px;
    display: flex;
    align-items: center;    
    /* justify-content: space-around; */
    margin: 10px 0 10px 0;
}

.center{
    margin-left: auto;
    margin-right: auto;
}

.rButton:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0);
}

@media screen and (max-width: 1024px) {
    .rDataContainer{
        flex-direction: column;
        height: fit-content;

    }

    .rDataFrame{
        width: 90%;
        margin-top: 5px;
        margin-bottom: 5px;
        
    }

    .stuff-container{
        width: 90%;
        height: 400px;
    }

    .coveredListContainer{
        width: 91%;
    }

    .coverItem{
        width: 90%;
    }


}

@media screen and (max-width: 700px){
    .coveredListTitle{
        flex-direction: column;
        margin-right: 0;
    }

    .clText{
        margin-left: 0;
    }

    .rButton{
        margin-left: auto;
        margin-right: auto;
    }

    .cityStatusTable{
        width: 96%;
        font-size: 1.1rem;
    }

    th{
        /* color: white; */
        font-size: 1.2rem;
    }
}